<template>
  <div>
    <!-- <v-container fluid class="down-top-padding"> -->
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-form ref="form" lazy-validation>
          <v-layout wrap>
            <v-flex xs12 pt-2>
              <v-card>
                <v-card-title class="elevation-1">
                  Theme Enquiry
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="3">
                    <v-select
                      outlined
                      v-model="addedby"
                      :items="affilatorArray"
                      item-value="_id"
                      item-text="firstname"
                      dense
                      label=" Select Affiliator"
                      required
                    ></v-select>
                  </v-col>
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="list"
                  :items-per-page="count"
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:[`item._id`]="{ item }">
                    <v-dialog v-model="item.delete" max-width="600px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          class="mr-2"
                          v-on="on"
                          v-bind="attrs"
                          color="red"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <v-card>
                        <v-card-title
                          >Are you sure you want to delete this Theme
                          Enquiry?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="item.delete = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteItem(item)"
                            >OK</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                </v-data-table>

                <div class="pt-2" v-if="pages > 1">
                  <v-pagination
                    :length="pages"
                    :total-visible="7"
                    v-model="currentPage"
                    color="#FF0000"
                  ></v-pagination>
                </div>
              </v-card>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      ServerError: false,
      showsnackbar: false,
      stylImage: null,
      msg: null,
      dialog: false,
      name: "",
      order: null,
      appLoading: false,
      currentPage: 1,
      pages: 0,
      count: 20,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      changeStatusDialog: false,
      search: "",
      itemname: "",
      imgId: "",
      addcat: false,
      affilatorArray: [],
      addedby: "",
      headers: [
        { text: "Theme Name", value: "theme.name" },
        { text: "WhatsApp Number", value: "whatsapp" },
        // { text: "Phone", value: "phone" },
        // { text: "Email", value: "email" },
        { text: "Actions", value: "_id", sortable: false },
      ],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      list: [],
      data: [],
    };
  },
  watch: {
    addedby() {
      this.getData();
    },
    currentPage() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
    this.getAffilator();
  },
  methods: {
    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/shopbylook/enquiry/remove",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;

            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/shopbylook/enquiry/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
          addedby: this.addedby,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.list = response.data.data;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getAffilator() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/affliator/dropdown",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.affilatorArray = response.data.data;
          this.affilatorArray.unshift({ firstname: "All", _id: null });
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.msg = "Server Unreachable";
          this.showsnackbar = true;
        });
    },
  },
};
</script>

<style scoped>
.table-striped:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
